import Enum from "../../../../../enums/Enum";

const EnumInsuranceReferralType = new Enum({ //todo: refactor, replace with EnumDirectBillingType
    REFERRAL: {name: "Referral"},
    CONSULTATION: {name: "Consultation"},
    PROCEDURE: {name: "Procedure"},
    VALIDITY_EXTENSION: {name: "Validity Extension"},
    ADDITIONAL_SESSIONS: {name: "Additional Sessions"},

    CHIROPRACTOR: {name: "Chiropractor"},
    CLINICAL_PSYCHOLOGIST: {name: "Clinical Psychologist"},
    FAMILY_MEDICINE: {name: "Family Medicine Specialist"},
    FAMILY_MEDICINE_CONSULTANT: {name: "Family Medicine Consultant"},
    KINESIOLOGY: {name: "Kinesiologist"},
    MASSAGE_THERAPIST: {name: "Massage Therapist"},
    MOVEMENT_THERAPIST: {name: "Movement Therapist"},
    NURSE: {name: "Nurse"},
    ORTHOPEDIC_SURGERY_SPECIALIST: {name: "Orthopedic Surgery - Specialist"},
    OSTEOPATH: {name: "Osteopath"},
    PHYSIATRIST: {name: "Physiatrist"},
    PHYSIOTHERAPIST: {name: "Physiotherapist"},
    SPORTS_FAMILY_MEDICINE: {name: "Sports and Family Medicine Specialist"},
    SPORTS_THERAPIST: {name: "Sports Therapist"}
});

export default EnumInsuranceReferralType;
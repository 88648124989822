import React, {Component} from "react";
import queryString from "query-string";
import {Password} from "primereact/password";
import {Button} from "react-bootstrap";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../components/form";
import AuthService from "../../../services/auth-service/AuthService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationService from "../../../services/notification-service/NotificationService";
import {PASSWORD_NOT_VALID_MESSAGE, passwordNotValid,} from "../../../services/auth-service/PasswordValidator";

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (AuthService.isAuthenticated()) {
            AuthService.logout();
            window.location.reload();
        }
    }

    resetPassword() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const secret = queryString.parse(this.props.location.search).secret;

        if (!secret) {
            NotificationService.showNotification({
                severity: "error",
                detail: "Missing token"
            });
            return;
        }

        const password = this.state.password;

        AuthService.setPassword(secret, password,
            (response) => {
                this.setState({success: true, message: response.data});
            },
            (error) => {
                if (error.response.status === 400) {
                    const errorMessage = error.response.data.errors.map(err => err.defaultMessage).join(', ');
                    NotificationService.showNotification({
                        severity: "error",
                        detail: errorMessage
                    });
                } else {
                    this.setState({success: false, message: error.response.data});
                }
            });
    }

    render() {
        return !!this.state.message ? this.renderMessageForm() : this.renderResetForm();
    }

    renderMessageForm() {
        return (
            <div className="d-flex flex-row p-justify-center vertical-align-center">
                {
                    this.state.success ?
                        <FontAwesomeIcon icon="check-circle" color="green" size="3x"/> :
                        <FontAwesomeIcon icon="times-circle" color="red" size="3x"/>
                }
                <h3 className="ml-2">{this.state.message}</h3>
            </div>
        )
    }

    renderResetForm() {
        this.formFields = [];

        return (
            <div className="container" style={{width: "36rem"}}>
                <div className="row">
                    <div className="col-4">
                        New Password
                    </div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.password}
                                   customValidator={password => {
                                       return !passwordNotValid(password);
                                   }}
                                   customValidatorMessage={PASSWORD_NOT_VALID_MESSAGE}
                        >
                            <Password value={this.state.password}
                                      onChange={(e) => this.setState({password: e.target.value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        Confirm New Password
                    </div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required equal={this.state.password} equalMessage="Passwords do not match."
                                   validateOn={this.state.password2}>
                            <Password feedback={false}
                                      value={this.state.password2}
                                      onChange={(e) => this.setState({password2: e.target.value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex p-justify-end">
                        <Button onClick={() => this.resetPassword()}>Reset Password</Button>
                    </div>
                </div>
            </div>
        )
    }

}
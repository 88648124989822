import React from 'react';
import "./Login.css";
import AuthService from "../../../services/auth-service/AuthService";
import NotificationService from "../../../services/notification-service/NotificationService";
import {onlyUnique} from "../../../utils/ArrayUtil";
import {passwordNotValid} from "../../../services/auth-service/PasswordValidator";

class LoginPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username,
            password: ' ',
            submitted: false
        };
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {username, password} = this.state;

        if (passwordNotValid(password, true)) return;

        AuthService.login(username, password)
            .then(
                user => {
                    AuthService.retrieveUserData(userData => {
                        Object.assign(user, userData);
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('clinics', JSON.stringify(user.clinics.map(c => c.id)));
                        localStorage.setItem('countries', JSON.stringify(user.clinics.map(c => c.country).filter(onlyUnique)));
                        this.props.close();
                    });
                },
                error => {
                    NotificationService.showNotification({
                        severity: 'error',
                        summary: 'Authentication Error',
                        detail: 'Bad Credentials'
                    });
                }
            );

    }

    render() {
        const {username, password, submitted} = this.state;
        return (
            <div className="login-page h-100">
                <div className="col-md-3 col-md-offset-3 login-form">
                    <form name="form" onSubmit={this.handleSubmit.bind(this)}>
                        <div className='form-group'>
                            <label htmlFor="username">E-mail</label>
                            <input type="text" className="form-control" name="username" value={username} readOnly/>
                        </div>
                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" name="password" value={password}
                                   onChange={this.handleChange.bind(this)}/>
                            {submitted && !password &&
                            <div className="help-block">Password is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <button className="btn-success">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginPopup;

import NotificationService from "../notification-service/NotificationService";

const PASSWORD_MIN_LENGTH = 12;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).+$/
export const PASSWORD_NOT_VALID_MESSAGE = 'Your password should be' +
    '\n\tat least 12 characters long \ncontaining at least' +
    '\n\tone digit\n\tone uppercase\n\tone lowercase\n\tone special character'

export function passwordNotValid(password, showNotification = false) {
    if (password?.length >= PASSWORD_MIN_LENGTH && PASSWORD_REGEX.test(password)) {
        return false
    } else {
        if (showNotification) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: PASSWORD_NOT_VALID_MESSAGE
            })
        }
        return true
    }
}


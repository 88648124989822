import Tooltip from "../../components/tooltip/Tooltip";
import {Image} from "react-bootstrap";
import React from "react";
import {Column} from "primereact/column";
import DateUtil from "../../utils/DateUtil";
import EnumBillTypeShort from "../../modules/patient/enums/EnumBillTypeShort";
import {DataTable} from "primereact/datatable";
import EnumInsurancePaymentStatus from "../../modules/billing/enums/EnumInsurancePaymentStatus";
import StringUtil from "../../utils/StringUtil";
import NumberUtils from "../../utils/NumberUtil";
import EnumInvoiceCancellationReason from "../../modules/billing/enums/EnumInvoiceCancellationReason";
import {TextInput} from "../../components";
import MoneyUtil from "../../utils/MoneyUtil";

function insurancePaymentStatusTemplate(invoice) {
    return invoice.insurancePaymentStatus ? EnumInsurancePaymentStatus[invoice.insurancePaymentStatus] : '';
}

function billStatusTemplate(item) {
    let iconName;
    let tooltip;

    switch (item.status) {
        case 'BILLED':
            iconName = 'billed.png';
            tooltip = "Billed";
            break;
        case 'UNBILLED':
            iconName = 'unbilled.png';
            tooltip = "Unbilled";
            break;
        case 'CANCELLED':
            iconName = 'cancelled.png';
            tooltip = "Cancelled Inv.";
            break;
        case 'DELETED':
            iconName = 'deleted.png';
            tooltip = "Deleted";
            break;
        case 'TO_BE_BILLED':
            iconName = 'pending.png';
            tooltip = "To Be Billed";
            break;
        case 'PENDING_ONLINE_PAYMENT':
            iconName = 'pending.png';
            tooltip = "Waiting For Online Payment";
            break;
        default:
            iconName = 'pending.png';
            tooltip = "Pending";
    }

    const iconSrc = process.env.PUBLIC_URL + '/assets/' + iconName;

    return (
        <Tooltip title={tooltip} placement="bottom">
            <Image src={iconSrc} rounded width={"15px"}/>
        </Tooltip>
    );
}

function noteTemplate(item) {
    return (
        <Tooltip key={item.id} placement="top" title={item.note}>
            <div>{StringUtil.shorten(item.note, 50)}</div>
        </Tooltip>
    )
}

function hydrateInvoiceItem(invoiceItem, items) {
    const item = invoiceItem.itemId ? items.find(i => i.id === invoiceItem.itemId) :
        {code: invoiceItem.code, description: invoiceItem.description};
    invoiceItem.item = item;
}

function hydrateInvoice(invoice, items) {
    invoice.items.map(i => hydrateInvoiceItem(i, items));

    invoice.gross = invoice.items.reduce(((a, b) => a + b.gross), 0);
    invoice.net = invoice.items.reduce(((a, b) => a + b.net), 0);
    invoice.coPay = invoice.items.reduce(((a, b) => a + b.coPay), 0);
    invoice.sponsorship = invoice.items.reduce(((a, b) => a + b.sponsorship), 0);
    invoice.tax = invoice.items.reduce(((a, b) => a + b.tax), 0);
    invoice.total = NumberUtils.toFixedByCurrency(invoice.credit + invoice.patientPayable, invoice.clinic.currency);
    // dto has patientPayable and credit values

    return invoice;
}

function disabledRowClassFunction(item) {
    return {
        'disabledRow':
            'UNBILLED' === item.status ||
            'CANCELLED' === item.status ||
            'DELETED' === item.status
    };
}

function invoiceColumns(showItems = true, showDwDiscount = false) {
    const columns = [
        <Column headerStyle={{width: '80px'}} header="Clinic" field="clinic.name" sortable/>,
        <Column header="Invoice Number" headerStyle={{width: '120px'}} field="id" sortable
                body={(invoice) => ('DELETED' === invoice.status ? '' : invoice.id)}/>,
        <Column header="Visit Date" headerStyle={{width: '120px'}} field="visitDate" sortable
                body={(invoice) => DateUtil.formatDateMMM(invoice.visitDate ? invoice.visitDate : invoice.paymentDate)}/>,
        <Column header="Invoice Date" headerStyle={{width: '120px'}} field="invoiceDate" sortable
                body={(invoice) => DateUtil.formatDateMMM(invoice.invoiceDate)}/>,
        <Column header="Provider" field="providerName" headerStyle={{width: '200px'}} sortable/>,
        <Column header="Referred By" headerStyle={{width: '150px'}} field="referrer" sortable/>,
        <Column header="Bill Type" headerStyle={{width: '100px'}} field="billType"
                body={(invoice) => EnumBillTypeShort[invoice.billType]} sortable/>,
        <Column header="Gross Amount" headerStyle={{width: '120px'}} field="gross"
                body={moneyTemplate("gross")} sortable/>,
        <Column header="Net Amount" headerStyle={{width: '100px'}} field="net"
                body={moneyTemplate("net")} sortable/>,
        <Column className="text-break" header="Ref No" headerStyle={{width: '70px'}}
                field="insuranceApprovalCode" sortable/>,
        <Column header="Co-Pay" headerStyle={{width: '80px'}} field="coPay"
                body={moneyTemplate("coPay")} sortable/>,
        <Column header="Tax" headerStyle={{width: '80px'}} field="tax" body={moneyTemplate("tax")} sortable/>,
        <Column header="Sponsorship" headerStyle={{width: '120px'}} field="sponsorship" body={moneyTemplate("sponsorship")} sortable/>,
        showDwDiscount ? <Column header="DW Discount" headerStyle={{width: '100px'}} field="dwDiscount"
                                 body={moneyTemplate("dwDiscount")} sortable/> : null,
        <Column header="Credit" headerStyle={{width: '100px'}} field="credit" body={moneyTemplate("credit")} sortable/>,
        <Column header="Patient Payable" headerStyle={{width: '130px'}} field="patientPayable"
                body={moneyTemplate("patientPayable")} sortable/>,
        <Column header="Bill Status" headerStyle={{width: '70px'}} field="status" body={billStatusTemplate}
                className="text-center"/>,
        <Column header="Delay Reason" headerStyle={{width: '100px'}} field="delayReason"/>
    ];

    if (showItems) {
        columns.unshift(<Column expander={true} style={{width: '20px'}}/>);
    }

    return columns;
}

function invoiceItemColumns(fullView, showDwDiscount = true , inputTextTemplate) {
    const columns = [
        <Column header="Clinic" field="clinic.name" sortable/>,
        <Column header={fullView ? "Visit Date" : "Visit Dt."} field="date" sortable
                body={(invoiceItem) => DateUtil.formatDateDayAndMonth(invoiceItem.visitDate)}/>,
        <Column header={fullView ? "Invoice Date" : "Inv. Dt."} field="date" sortable
                body={(invoiceItem) => DateUtil.formatDateDayAndMonth(invoiceItem.date)}/>,
        <Column header="Provider" field="provider.name" style={{width: '10em'}} sortable
                body={(invoiceItem) => invoiceItem.category === "CPT" ? invoiceItem.provider?.name : "Cashier"}/>,
        <Column header={fullView ? "Referred By" : "Refr. By."} body={(invoiceItem) => invoiceItem.referrer != null ?
            invoiceItem.referrer.name : invoiceItem.referrerName} style={{width: '10em'}} sortable/>,
        <Column header="Code" field="code" sortable/>,
        <Column header="Item" field="description" style={{width: '25em'}} sortable/>,
        <Column header={fullView ? "Quantity" : "Qty."} field="quantity" sortable/>,
        <Column header={fullView ? "Bill Type" : "Type"} field="billType"
                body={(invoiceItem) => EnumBillTypeShort[invoiceItem.billType]} sortable/>,
        <Column header="Gross" field="gross" sortable body={(invoiceItem)  => inputTextTemplate(invoiceItem, "gross")}/>,
        <Column header="Net" field="net" body={(invoiceItem)  => inputTextTemplate(invoiceItem, "net")} sortable/>,
        <Column className="text-break" header="Ref No" field="insuranceApprovalCode" sortable/>,
        <Column header={fullView ? "Co-Pay" : "Co-P"} field="coPay" sortable body={(invoiceItem)  => inputTextTemplate(invoiceItem, "coPay")}/>,
        <Column header={fullView ? "Sponsorship" : "Spsr."} field="sponsorship" body={moneyTemplate("sponsorship")} sortable/>,
        <Column header="Tax" field="tax" body={moneyTemplate("tax")} sortable/>,
        showDwDiscount ?
            <Column header="DW Discount" field="dwDiscount" body={moneyTemplate("dwDiscount")} sortable/> : null,
        <Column header="Credit" field="credit" sortable  body={(invoiceItem)  => inputTextTemplate(invoiceItem, "credit" )}/>,
        <Column header={fullView ? "Delay Reason" : "Delay Rsn."} field="delayReason" style={{width: '7em'}} sortable/>,
        <Column className="text-break" header="Cancellation Reason" field="cancellationReason"
                body={(invoiceItem) => invoiceItem.cancellationReason !== '' ?
                    (EnumInvoiceCancellationReason[invoiceItem.cancellationReason] || invoiceItem.cancellationReason) : invoiceItem.cancellationDetail}
                style={{width: '7em'}} sortable/>,
        <Column header={fullView ? "Patient Payable" : "Ptnt. Pay"} body={(invoiceItem)  => inputTextTemplate(invoiceItem, "patientPayable")}
                field="patientPayable" sortable/>,
        <Column header={fullView ? "Bill Status" : "Status"} field="status" body={billStatusTemplate}
                className="text-center"/>
    ];

    if (fullView) {
        columns.push(<Column header="Notes" field="note" body={noteTemplate}/>);
    }

    return columns;
}

function moneyTemplate(fieldName) {
    return rowData => {
        const value = rowData[fieldName];
        const fractionDigits = MoneyUtil.getFractionDigitsWithCountry(rowData["clinic"].country);
        return (value === undefined || value === null) ? NumberUtils.toFixed(0, fractionDigits) :
            NumberUtils.toFixed(value, fractionDigits);
    }
}

function invoiceItemsExpansionTemplate(invoice, fullView, inputTextTemplate, showDwDiscount) {
    return (
        <div className="invoice-item-expansion-wrapper row" style={{padding: "20px", animation: "fadeAnimation 1s"}}>
            <DataTable className="InvoiceItemsTable"
                       rowClassName={this.disabledRowClassFunction}
                       value={invoice.items}>
                {invoiceItemColumns(fullView, showDwDiscount , inputTextTemplate)}
            </DataTable>
        </div>
    );
}

export default {
    billStatusTemplate, hydrateInvoiceItem, hydrateInvoice, disabledRowClassFunction,
    invoiceColumns, invoiceItemColumns, invoiceItemsExpansionTemplate, insurancePaymentStatusTemplate, moneyTemplate
};
